import { Link } from "@inertiajs/react";

export default function ResponsiveNavLink({
    active = false,
    className = "",
    children,
    ...props
}) {
    return (
        <a
            {...props}
            className={
                `w-full flex items-start pl-3 pr-4 py-2 border-b-2 font-medium leading-5 transition duration-150 ease-in-out cursor-pointer ` +
                (active
                    ? "border-outragous-orange text-astronaut-blue focus:outline-none focus:border-outragous-orange "
                    : "border-transparent text-gray-600 hover:text-astronaut-blue focus:outline-none") +
                className
            }
        >
            {children}
        </a>
    );
}
