import { useEffect } from "react";

export default function NavLinkLanding({
    active = false,
    className = "",
    children,
    ...props
}) {
    return (
        <a
            {...props}
            className={
                `inline-flex items-center mx-2 xl:mx-4 px-1 pt-1 border-b-2 font-medium leading-5 transition duration-150 ease-in-out cursor-pointer ` +
                (active
                    ? "border-outragous-orange text-astronaut-blue focus:outline-none focus:border-outragous-orange "
                    : "border-transparent text-gray-600 hover:text-astronaut-blue hover:border-astronaut-blue focus:outline-none") +
                className
            }
        >
            {children}
        </a>
    );
}
